import React from "react"

import * as styles from "./EventLocationWarning.module.scss"

const EventLocationWarning = ({ title, description }) => (
    <div className={styles.EventLocationWarning}>
        <h3>{title}</h3>
        <div className={styles.description}>
            <p>{description}</p>
        </div>
    </div>
)

export default EventLocationWarning
