import React from "react"

import * as styles from "./EventMeta.module.scss"
import EventLocationWarning from "./EventLocationWarning"

const EventMeta = (props) => {
    const dates = props.dates.map((dateRange, idx) => {
        const startDate = new Date(dateRange.startDate)
        const endDate = new Date(dateRange.endDate)

        const dateFrom = startDate.getMonth() === endDate.getMonth() ? startDate.getDate() : dateRange.startDate
        const dateString = dateRange.startDate === dateRange.endDate ? dateRange.startDate : `${dateFrom} - ${dateRange.endDate}`
        return <span key={`event-date-${idx}`}>{dateString}</span>
    })

    const hide_venue_name = props.offsiteEventWarning.hideVenueName === true

    // if we have no dates to show, return an empty view
    if (dates.length === 0) {
        return <></>
    }

    return (
        <div className={styles.EventMeta}>
            <div className={styles.information}>
                <span className={styles.date}>{dates}</span>
                {!hide_venue_name && <span className={styles.venue} dangerouslySetInnerHTML={{ __html: props.venue }}></span>}
                <span dangerouslySetInnerHTML={{ __html: props.times }}></span>
                <span>{props.cost}</span>
            </div>

            {props.showBookNowButton && (
                <div className={styles.actions}>
                    <a href={props.bookNowUrl}>Book now</a>
                </div>
            )}

            {props.offsiteEventWarning.display && (
                <EventLocationWarning title={props.offsiteEventWarning.title} description={props.offsiteEventWarning.description} />
            )}
        </div>
    )
}

export default EventMeta
