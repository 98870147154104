import React from "react"

import * as styles from "./Sidebar.module.scss"

const Sidebar = ({children}) => (

    <div className={styles.Sidebar}>
        {children}
    </div>

)

export default Sidebar