import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getEventPreview as PREVIEW_QUERY } from "../preview-queries/eventPreview"

// Data
import { graphql } from "gatsby"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Sidebar from "../components/Shared/Sidebar"
import Article from "../components/Shared/Article"
import CTA from "../components/Shared/CTA"
import EventHero from "../components/Event/EventHero"
import EventMeta from "../components/Event/EventMeta"
import EventLeaderList from "../components/Shared/EventLeaderList"
import EventLeader from "../components/Shared/EventLeader"
import Content from "../components/Shared/Content"
import SectionTitle from "../components/Shared/SectionTitle"
import CentredContentWithSidebar from "../components/Shared/CentredContentWithSidebar"
import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const EventTemplate = (props) => {
    const event = getContent(props.data, "event")
    const relatedEventsIds = props.data.relatedEvents.edges.map((event) => event.node.id)
    const nextEvents = props.data.nextEvents.edges.filter((event) => relatedEventsIds.indexOf(event.node.id) < 0)

    const relatedEvents = [...props.data.relatedEvents.edges, ...nextEvents]
        .filter((event, index, self) => self.indexOf(event) === index)
        .map((result) => result.node)
        .slice(0, 3)

    const venue = venueForPage({
        page: event,
        menus: props.data.menus,
    })

    // console.log("Event props ", props)
    // console.log("Event venue ", venue)

    const eventTheme = event.event_fields.displayTheme
    let themeClass = "EventTheme"

    const mulberry32 = (a) => {
        return function () {
            var t = (a += 0x6d2b79f5)
            t = Math.imul(t ^ (t >>> 15), t | 1)
            t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
            return ((t ^ (t >>> 14)) >>> 0) / 4294967296
        }
    }

    switch (eventTheme) {
        case "theme_one":
            themeClass += "1"
            break
        case "theme_two":
            themeClass += "2"
            break
        case "theme_three":
            themeClass += "3"
            break
        default:
            // Create a random theme class based on title
            var ar = event.title.substring(0, 3).split("")
            for (let i = 0; i < ar.length; i++) {
                ar[i] = ar[i].charCodeAt(0)
            }
            if (typeof Math.imul === "undefined") {
                themeClass = "EventTheme1"
            } else {
                let rng = mulberry32(Number(ar.join("")))
                themeClass += Math.floor(rng() * 3) + 1
            }
    }

    const sortedUpcomingEventDates = (dates) => {
        // filter any date range rows that are in the past
        const eventDates = dates.filter((dateRange) => {
            return new Date(dateRange.endDate) >= new Date(new Date().setHours(0, 0, 0, 0))
        })

        const datesSort = (a, b) => {
            const d1 = new Date(a.startDate)
            const d2 = new Date(b.startDate)

            return d1 > d2 ? 1 : -1
        }

        return eventDates.sort(datesSort)
    }

    const eventDateString = (dates, fullRange = false) => {
        if (!dates) return ""

        // sort the remaining date ranges
        const sortedDates = sortedUpcomingEventDates(dates)

        if (sortedDates.length > 0) {
            const first = sortedDates[0]
            const last = fullRange ? sortedDates.reverse()[0] : sortedDates[0]

            if (first.startDate === last.endDate) {
                return `${first.startDate}`
            } else {
                return `${first.startDate} - ${last.endDate}`
            }
        } else {
            return ""
        }
    }

    return (
        <Layout venue={venue} currentLocation={props.path} title={event.title}>
            <SEOSettings entity={event} />
            <EventHero
                image={event.image.image}
                title={event.title}
                subtitle={``}
                venue={event.venueFields?.venue?.title}
                date={eventDateString(event.event_fields.eventDates, true)}
                backlink="/whats-on"
                theme={themeClass}
            />

            <Main theme={themeClass}>
                <CentredContentWithSidebar>
                    <Content>
                        {/*<EventTitle>Process and progress The creative journeys of Ronald Pope and the artists of the Jonathan Vickers Fine Art Award</EventTitle>*/}
                        <Article>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: event.content,
                                }}
                            ></div>
                        </Article>
                    </Content>

                    <Sidebar>
                        <EventMeta
                            times={event.event_fields.times}
                            venue={event.venueField?.venue ? event.venueField?.venue?.title : undefined}
                            cost={event.event_fields.cost}
                            dates={sortedUpcomingEventDates(event.event_fields.eventDates)}
                            offsiteEventWarning={event.offsite_event_warning}
                            showBookNowButton={event.event_fields.showBookNowButton}
                            bookNowUrl={event.event_fields.bookNowUrl}
                        />

                        {event.event_fields.sidebarCta && (
                            <CTA title={event.event_fields.sidebarCta.title} cta_fields={event.event_fields.sidebarCta.cta_fields} />
                        )}
                    </Sidebar>
                </CentredContentWithSidebar>

                <SectionTitle>You may also be interested in</SectionTitle>

                <EventLeaderList layoutType="threeColumn">
                    {relatedEvents.map((event) => (
                        <EventLeader key={event.id} {...event} />
                    ))}
                </EventLeaderList>
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query EventQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        event: wpEvent(databaseId: { eq: $databaseId }) {
            id
            title
            publishToLive {
                publishToLive
            }
            content
            link
            excerpt
            image: hero_fields {
                image {
                    sourceUrl
                    altText
                    title
                }
            }
            offsite_event_warning {
                display: displayEventLocationWarning
                description: warningMessage
                title: warningTitle
                hideVenueName
            }
            event_fields {
                eventDates {
                    startDate
                    endDate
                }
                times
                cost
                displayTheme
                showBookNowButton
                bookNowUrl
                sidebarCta {
                    ... on WpCallstoAction {
                        title
                        cta_fields {
                            link {
                                title
                                target
                                url
                            }
                            image {
                                title
                                sourceUrl
                            }
                            description
                        }
                    }
                }
            }
            venueFields {
                venue {
                    ...VenueMenuSettings
                    ... on WpVenue {
                        id
                        title
                        venue_fields {
                            addressAndOpeningTimes {
                                address
                            }
                        }
                    }
                }
            }
            seo_settings {
                metaDescription
                metaKeywords
                robotInstructions
                seoTitle
            }
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                    sourceUrl
                }
            }
        }

        relatedEvents: allWpEvent(limit: 4) {
            edges {
                node {
                    id
                    title
                    content
                    link
                    image: hero_fields {
                        image {
                            sourceUrl
                            title
                        }
                    }
                    offsite_event_warning {
                        display: displayEventLocationWarning
                        description: warningMessage
                        title: warningTitle
                        hideVenueName
                    }
                    event_fields {
                        eventDates {
                            startDate
                            endDate
                        }
                    }
                    venueFields {
                        venue {
                            ... on WpVenue {
                                id
                                title
                                venue_fields {
                                    addressAndOpeningTimes {
                                        address
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        nextEvents: allWpEvent(limit: 4) {
            edges {
                node {
                    id
                    title
                    content
                    link
                    image: hero_fields {
                        image {
                            sourceUrl
                            title
                        }
                    }
                    offsite_event_warning {
                        display: displayEventLocationWarning
                        description: warningMessage
                        title: warningTitle
                        hideVenueName
                    }
                    event_fields {
                        eventDates {
                            startDate
                            endDate
                        }
                    }
                    venueFields {
                        venue {
                            ... on WpVenue {
                                id
                                title
                                venue_fields {
                                    addressAndOpeningTimes {
                                        address
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #wpgraphql {
        #    ...PageMenus
        #    event(id: $databaseId, idType: DATABASE_ID) {
        #        id
        #        title
        #        content
        #        link
        #        excerpt
        #        image: hero_fields {
        #            image {
        #                sourceUrl
        #                altText
        #                title
        #            }
        #        }
        #        offsite_event_warning {
        #            display: displayEventLocationWarning
        #            description: warningMessage
        #            title: warningTitle
        #            hideVenueName
        #        }
        #        event_fields {
        #            eventDates {
        #                startDate
        #                endDate
        #            }
        #            times
        #            cost
        #            displayTheme
        #            showBookNowButton
        #            bookNowUrl
        #            sidebarCta {
        #                ... on WPGraphQL_CTA {
        #                    title
        #                    cta_fields {
        #                        link {
        #                            title
        #                           target
        #                           url
        #                       }
        #                       image {
        #                           title
        #                           sourceUrl
        #                       }
        #                       description
        #                   }
        #               }
        #           }
        #       }
        #       venue {
        #           venue {
        #               ...VenueMenuSettings
        #               ... on WpVenue {
        #                   id
        #                   title
        #                   venue_fields {
        #                       addressAndOpeningTimes {
        #                           address
        #                       }
        #                   }
        #               }
        #           }
        #       }
        #       seo_settings {
        #           metaDescription
        #           metaKeywords
        #           robotInstructions
        #           seoTitle
        #       }
        #   }
        #
        #   relatedEvents: events(first: 4, where: { eventType: $eventTypeId, date: $eventDate, notIn: [$databaseId] }) {
        #      edges {
        #          node {
        #              id
        #              title
        #              content
        #              link
        #              image: hero_fields {
        #                  image {
        #                      sourceUrl
        #                      title
        #                  }
        #              }
        #              offsite_event_warning {
        #                  display: displayEventLocationWarning
        #                  description: warningMessage
        #                  title: warningTitle
        #                  hideVenueName
        #              }
        #              event_fields {
        #                  eventDates {
        #                      startDate
        #                      endDate
        #                  }
        #              }
        #              venue {
        #                  venue {
        #                      ... on WpVenue {
        #                          id
        #                          title
        #                          venue_fields {
        #                              addressAndOpeningTimes {
        #                                  address
        #                              }
        #                          }
        #                      }
        #                  }
        #              }
        #          }
        #      }
        #  }
        # nextEvents: events(first: 4, where: { date: $eventDate, notIn: [$databaseId] }) {
        #     edges {
        #         node {
        #             id
        #             title
        #             content
        #             link
        #             image: hero_fields {
        #                 image {
        #                     sourceUrl
        #                     title
        #                 }
        #             }
        #             offsite_event_warning {
        #                 display: displayEventLocationWarning
        #                 description: warningMessage
        #                 title: warningTitle
        #                 hideVenueName
        #             }
        #             event_fields {
        #                 eventDates {
        #                     startDate
        #                     endDate
        #                 }
        #             }
        #             venue {
        #                 venue {
        #                     ... on WpVenue {
        #                         id
        #                         title
        #                         venue_fields {
        #                             addressAndOpeningTimes {
        #                                 address
        #                             }
        #                         }
        #                     }
        #                 }
        #             }
        #         }
        #     }
        # }
        #}
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(EventTemplate)
