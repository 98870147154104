import React from "react"
import { decode } from "../../helpers/stringHelpers"

// Styles
import * as styles from "./EventHero.module.scss"

// Components
import PlaceholderImage from "../Shared/PlaceHolderImage"
import ParallaxItem from "../Shared/ParallaxItem"
import Image from "../Shared/Image";
import {imageSizes, placeholderImage} from "../../helpers/imgix";

const EventHero = (props) => {
    return (
        <div className={`${styles.EventHero} ${styles[props.theme]}`}>
            <div className={styles.image}>
              <ParallaxItem>
                {props.image &&
                  <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.hero} />
                }
              </ParallaxItem>
            </div>
            <div className={styles.textContent}>
                <h1>{decode(props.title)} </h1>
                <h2>{props.subtitle}</h2>
                <span className={styles.venue}>{props.venue}</span>
                <span className={styles.date}>{props.date}</span>
            </div>

            <div className={styles.seeAll}>
                <a href={props.backlink}>See all events</a>
            </div>
        </div>
    )
}

export default EventHero
