import gql from "graphql-tag"

export const getEventPreview = gql`
    query EventPreviewQuery($id: ID!, $revision_id: Int!) {
        event(id: $id, idType: DATABASE_ID) {
            revisions(where: { id: $revision_id }) {
                edges {
                    node {
                        id
                        title
                        content
                        link
                        image: hero_fields {
                            image {
                                sourceUrl
                                altText
                                title
                            }
                        }
                        offsite_event_warning {
                            display: displayEventLocationWarning
                            description: warningMessage
                            title: warningTitle
                            hideVenueName
                        }
                        event_fields {
                            eventDates {
                                startDate
                                endDate
                            }
                            times
                            cost
                            displayTheme
                            showBookNowButton
                            bookNowUrl
                            sidebarCta {
                                ... on CTA {
                                    title
                                    cta_fields {
                                        link {
                                            title
                                            target
                                            url
                                        }
                                        image {
                                            title
                                            sourceUrl
                                        }
                                        description
                                    }
                                }
                            }
                        }
                        venue {
                            venue {
                                ... on Venue {
                                    id
                                    title
                                    slug
                                    venue_fields {
                                        hasCustomNavigationMenu
                                        venueNavigationMenuId
                                        addressAndOpeningTimes {
                                            address
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
